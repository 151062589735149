import React from 'react';
import { PhoneOutlined, WhatsAppOutlined } from '@ant-design/icons';

import Layout from '../components/Layout';
import SEO from '../components/seo/SEO';
import FeaturedTestimonial from '../components/FeaturedTestimonial';
import Benefits from '../components/Benefits';
import Address from '../components/Address';
import ContactForm from '../components/ContactForm';
import useI18n from '../utils/useI18n';

const Contact = ({ pageContext: { switchLang }, location }) => {
  const { t } = useI18n();
  return (
    <Layout switchLang={switchLang}>
      <SEO
        title={t('contact.meta.title')}
        description={t('contact.meta.description')}
        keywords={t('contact.meta.keywords')}
        pathname={location.pathname}
        altTitle={t('menu.contact')}
      />
      <div className="o-wrapper u-align-center">
        <div className="u-margin-top u-10/12@desktop u-push-1/12@desktop">
          <h1 className="o-largeTitle">{t('contact.title')}</h1>
          <p className="o-callout">{t('contact.text')}</p>
          <span className="u-h2">{t('phone')}</span>
          <span>{t('time')}</span>
          <div className="u-margin-top">
            <a
              href="tel:8882753595"
              className="c-button c-button--large u-margin-right"
              onClick={() => {
                if (window.gtag) {
                  window.gtag('event', 'call', {
                    event_category: 'botón',
                    event_label: 'contacto',
                    value: 1,
                  });
                }
              }}
            >
              <PhoneOutlined /> {t('contact.callUs')}
            </a>
            <a
              onClick={() => {
                if (window.ga) {
                  window.ga('send', 'event', 'wapp', 'mandar msj', 'whatsapp web', 1);
                }
              }}
              href="https://api.whatsapp.com/send?phone=5213325107552&text=Hi,%20I%20need%20help%20for%20cancel%20my%20timeshare%20contract"
              className="c-button c-button--large c-button--secondary"
            >
              {t('contact.whatsApp')} <WhatsAppOutlined />
            </a>
            <span className="u-h4 u-margin-top-large u-margin-bottom">
              {t('contact.secondaryText')}
            </span>
          </div>
          <ContactForm />
        </div>
      </div>
      <Address />
      <FeaturedTestimonial />
      <Benefits contact={false} />
    </Layout>
  );
};

export default Contact;
